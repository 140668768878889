/* tslint:disable */
// @ts-nocheck
import { ViewportScroller } from '@angular/common';
import { Component, OnInit, Renderer2 } from '@angular/core';
import { NavigationError, NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from './../environments/environment';
import { CommonDataService } from './core/services/common-data.service';
import { slideInAnimation } from './route-animations';
import { PunkuRoutes } from './core/utils/punkuRoutes';
import { FormStorageManagerService } from './core/services/form-storage-manager.service';
import { ScriptService } from './core/services/script.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [slideInAnimation],
  providers: [CommonDataService],
})
export class AppComponent implements OnInit {
  public showSidenav: boolean = false;
  public showSidenavBroker: boolean;
  public showLoader: boolean;
  public toast: any;
  public isBrokerUser: boolean = false;
  public routes = PunkuRoutes.ROUTES.Pages;

  private loaderSubscription: Subscription;
  private toastSubscription: Subscription;
  private sideNavBrokerSubscription: Subscription;

  public isPageWLoader = false;
  public isStepThree = false;
  public isStepFour = false;
  public showWspBtn = false;

  constructor(
    private viewportScroller: ViewportScroller,
    private commonDataService: CommonDataService,
    private formStorageManagerService: FormStorageManagerService,
    private router: Router,
    private renderer: Renderer2,
    private scriptService: ScriptService
  ) {
    console.info('AppVersion:', environment.version);
    console.info('Env:', environment.FRONT_ENV);

  //   this.scriptService.loadNuibizScript(this.renderer);

  //   this.router.events.subscribe(async (event: Event) => {
  //     if (event instanceof NavigationStart) {
  //       if (event.url && event.url === '/') {
  //         this.isBrokerUser = false;
  //         this.showWspBtn = true;
  //       } else {
  //         this.isPageWLoader = true;
  //         this.commonDataService.changeLoader(true);
  //       }

  //       if (event.url.includes('quote')) {
  //         this.showWspBtn = true;
  //       }

  //       this.isStepThree = event.url.includes('three') && !event.url.includes('claim');
  //       this.isStepFour = event.url.includes('four');

  //       if (
  //         event.url === '/' ||
  //         event.url.includes(this.routes.AboutUs.fullPath) ||
  //         event.url.includes(this.routes.SelectFlow.fullPath) ||
  //         event.url.includes(this.routes.SelectFlowBroker.fullPath)
  //       ) {
  //         await this.formStorageManagerService.verifyQuoteInStorage();
  //       }

  //       const tokenBroker = this.formStorageManagerService.getBrokerToken();

  //       if (
  //         (tokenBroker &&
  //           (event.url.includes(this.routes.HomeBroker.fullPath) ||
  //             event.url.includes(this.routes.SelectFlowBroker.fullPath) ||
  //             event.url.includes(this.routes.WhoIsHiringBroker.fullPath) ||
  //             event.url.includes(
  //               this.routes.QuoteBroker.QuoteBrokerCompany.fullPath
  //             ) ||
  //             event.url.includes(
  //               this.routes.QuoteBroker.QuoteBrokerPerson.fullPath
  //             ) ||
  //             event.url.includes(
  //               this.routes.QuoteBroker.QuoteBrokerNaturalPerson.fullPath
  //             ) ||
  //             event.url.includes(this.routes.QuoteHistoryBroker.fullPath))) ||
  //         event.url.includes(this.routes.Niubiz.PropertyBroker.fullPath)
  //       ) {
  //         this.isBrokerUser = true;
  //       }

  //       if (event.url.includes(this.routes.HomeBroker.fullPath)) {
  //         setTimeout(() => {
  //           this.commonDataService.changeActiveSideNavBroker(true);
  //         }, 200);
  //       }
  //     }

  //     if (event instanceof NavigationError) {
  //       this.commonDataService.changeLoader(false);
  //     }
  //   });

  //   this.loaderSubscription = this.commonDataService.currentLoader.subscribe(
  //     (state) => {
  //       this.showLoader = state;
  //     }
  //   );

  //   this.toastSubscription = this.commonDataService.currentToast.subscribe(
  //     (state) => {
  //       this.toast = state;
  //     }
  //   );

  //   this.sideNavBrokerSubscription =
  //     this.commonDataService.currentActiveSideNavBroker.subscribe((value) => {
  //       this.showSidenavBroker = value;
  //     });
  // }

  // ngOnInit() {}

  // public prepareRoute(outlet: RouterOutlet) {
  //   return (
  //     outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation
  //   );
  // }

  // public onActivate($event): void {
  //   this.viewportScroller.scrollToPosition([0, 0]);
  // }

  // public onActivedSidenav(e) {
  //   if (this.isBrokerUser) {
  //     this.commonDataService.changeActiveSideNavBroker(e);
  //   } else {
  //     this.showSidenav = !this.showSidenav;
  //   }
  // }

  // ngOnDestroy() {
  //   if (this.loaderSubscription) this.loaderSubscription.unsubscribe();
  //   if (this.toastSubscription) this.toastSubscription.unsubscribe();
  //   if (this.sideNavBrokerSubscription)
  //     this.sideNavBrokerSubscription.unsubscribe();
  // }
  }
}
