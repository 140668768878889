import {
  OnInit,
  Component,
} from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-product-deregistration',
  templateUrl: './product-deregistration.component.html',
  styleUrls: ['./product-deregistration.component.scss']
})
export class ProductDeregistrationComponent implements OnInit {
  constructor(private router: Router) {

  }

  ngOnInit(): void {
    
  }

  redirectTo(url: string){
    window.location.href = url;
  }
}
