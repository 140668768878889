<section class="deregistration-container">
  <div class="header">
    <img
      class="logo__iglu"
      src="/assets/img/svg/logo-horizontal-light-blue.svg"
      alt="logo iglu"
    />
  </div>
  <div class="container-info">
    <div class="container-img">
      <img
        class="help__iglu"
        src="/assets/img/svg/settings.svg"
        alt="help"
      />
    </div>
    <p class="container-info_title">Estamos trabajando</p>
    <p class="container-info_title" style="margin-top: -18px;">para ofrecerte la mejor experiencia</p>
    <p class="container-info_subtitle">¿Listo para cotizar? Ingresa aquí y obtén más información de inmediato</p>

    <button
      class="btn btn-skyBlue"
      style="margin-bottom: 36px;"
      (click)="redirectTo('https://www.avla.com/pe/seguro-multirriesgo')"
    >
      <span class="btn-text">Quiero cotizar</span>
    </button>

    <div class="container-info-help">
      <p class="container-info_text">
        Si necesitas ayuda con tu póliza, por favor, comunícate con el Equipo Comercial <span class="container-info_span">ivillanueva@avla.com</span> o <span class="container-info_span">comercial_st@avla.com</span>
      </p>
    </div>
    <div class="container-info-help">
      <p class="container-info_text">
        Si quieres registrar un siniestro de tu póliza, contacta al Equipo de Siniestros de AVLA Perú <span class="container-info_span">siniestros_peru@avla.com</span>
      </p>
    </div>
    <p class="container-info_text" style="margin-top: 72px;">One Team <span class="avla"> AVLA </span></p>
    <div class="container-red">
      <img
        class="redIcon"
        src="/assets/img/svg/facebook.svg"
        alt="facebook"
        (click)="redirectTo('https://www.facebook.com/somosavlaperu/')"
      />
      <img
        class="redIcon"
        src="/assets/img/svg/linkedin.svg"
        alt="linkedin"
        (click)="redirectTo('https://www.linkedin.com/company/somosavlape')"
      />
      <img
        class="redIcon"
        src="/assets/img/svg/instagram.svg"
        alt="instagram"
        (click)="redirectTo('https://www.instagram.com/somosavlape/')"
      />
      <img
        class="redIcon"
        src="/assets/img/svg/youtube.svg"
        alt="youtube"
        (click)="redirectTo('https://www.youtube.com/c/AVLAcorp')"
      />
    </div>
  </div>
</section>
